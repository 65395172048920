import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  @media screen and (min-width: 481px) {
    text-align: center;
  }
`

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  background: linear-gradient(transparent 50%, #E0EC5C 50%);
  padding: 0 5px;
  margin-top: 10px;
`

const Text = styled.div`
  color: #555;
  font-weight: bold;
  font-family: lanove-pop;
  font-size: 1.2rem;
  padding-left: 5px;
  @media screen and (min-width: 481px) {
    font-size: 2.5vw;
  }
`

const Icon = styled.div`
  width: 20px;
  height: 20px;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 481px) {
    width: 2.2vw;
    height: 2.2vw;
  }
`

 export default function Section(props:any) {
  return (
    <Container>
      <Wrapper>
        <Icon>
          <img
            src={props.src}
            alt={props.chldren}
            />
        </Icon>
        <Text>
          {props.children}
        </Text>
      </Wrapper>
    </Container>
  );
}
