import styled from 'styled-components'

export const NukiButton = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  display: inline;
  font-weight: bold;
  font-family: lanove-pop;
  text-align: center;
  border-radius: 5px;
  color: #FFF;
  background-color: #FF6969;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.25);
  padding: 10px;
  letter-spacing: 0.15em;
  &:disabled {
    background-color: #CCC;
  }
  font-size: 1rem;
  font-weight: normal;
  width: 100%;
  padding: 15px;
  @media screen and (min-width: 481px) {
    max-width: 500px;
    font-size: 2vw;
  }
  `

export const SearchButton = styled(NukiButton)`
`
export const RuleButton = styled(NukiButton)`

`
export const NewWindowButton = styled(RuleButton)`
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  & > img {
    padding-left: 1vw;
    width: 4vw;
    height: 4vw;
  }
  @media screen and (min-width: 481px) {
    & > img {
      padding-left: 1vw;
      width: 1.5vw;
      height: 1.5vw;
    }
  }
`

export const RoundedButton = styled.button`
  cursor: pointer;
  font-size: 0.8rem;
  font-family: lanove-pop;
  text-align: center;
  color: #FFF;
  margin-top: 5px;
  padding: 10px;
  background-color: #FF6969;
  border-radius: 10vw;
  border: 0 solid #FFF;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.25);

  @media screen and (min-width: 481px) {
    font-size: 1.5vw;
  }
`