import React from "react";
import { useState } from "react";
import styled from "styled-components";
import AutoSuggest from "react-autosuggest";
import LazyLoad from "react-lazyload";
import { Type } from "./Texts";

export interface Suggestion {
  name: string;
  kana: string;
  image: string;
  id: string;
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  @media screen and (min-width: 481px) {
    padding: 0;
  }
`;

const UpperWrapper = styled(Type)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5vh;
`;
const TypeText = styled(Type)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4.3vw;
  height: 6vw;
  @media screen and (min-width: 481px) {
    font-size: 1.8vw;
  }
`;
const SelectedImage = styled.img`
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  margin-left: 1vw;
  @media screen and (min-width: 481px) {
    width: 2vw;
    height: 2vw;
  }
`;

const SelectItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 481px) {
    font-size: 2vw;
  }
`;
const SelectItemName = styled.div``;
const SelectItemImage = styled.img`
  width: 30px;
`;

function SelectItem(props: any) {
  return (
    <SelectItemWrapper>
      <SelectItemName>{props.name}</SelectItemName>
      <LazyLoad once>
        <SelectItemImage src={props.image} />
      </LazyLoad>
    </SelectItemWrapper>
  );
}
export default function SearchActress(props: any) {
  const [value, setValue] = useState(props.value);
  const [image, setImage] = useState(props.image);
  const [isReset, setIsReset] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  let isPC = false;
  if (matchMedia("screen and (min-width: 481px)").matches) {
    isPC = true;
  }

  function getSuggestions(value: string): Suggestion[] {
    const kanaHits = props.actresses.filter((actress: Suggestion) =>
      actress.kana.startsWith(value.trim())
    );
    const nameHits = props.actresses.filter((actress: Suggestion) =>
      actress.name.startsWith(value.trim())
    );
    return [...kanaHits, ...nameHits];
  }

  return (
    <Wrapper>
      <UpperWrapper>
        <TypeText>{props.type}</TypeText>
        {image && <SelectedImage src={image} alt={value} />}
      </UpperWrapper>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestionValue }) => {
          let suggestion = JSON.parse(suggestionValue);
          setValue(suggestion.name);
          setImage(suggestion.image);
          props.onChange(suggestion);
          setIsSelect(true);
        }}
        getSuggestionValue={suggestion => {
          return JSON.stringify(suggestion);
        }}
        renderSuggestion={suggestion => (
          <SelectItem
            name={suggestion.name}
            image={suggestion.image}
          ></SelectItem>
        )}
        inputProps={{
          placeholder: "女優名",
          value: value,
          style: {
            width: "100%",
            height: "40px",
            lineHeight: "40px",
            boxSizing: "border-box",
            textAlign: "center",
            verticalAlign: "center",
            padding: 0,
            fontSize: isPC ? "1.6vw" : "4vw",
            fontWeight: "bold",
            color: "#555",
            background: "transparent",
            border: "3px solid #888",
            WebkitAppearance: "none",
          },
          onChange: (event, { newValue, method }) => {
            if (!isPC && newValue.length - value.length > 1) {
              setIsReset(true);
              return false;
            }
            setValue(newValue);
            setImage("");
            props.onTextChange(newValue);
          },
          onCompositionEnd: event => {
            if (!isPC && isReset && isSelect) {
              const dom: any = event.nativeEvent.target;
              dom.value = "";
              setValue("");
              setIsReset(false);
              setIsSelect(false);
            }
          },
        }}
        highlightFirstSuggestion={true}
      />
    </Wrapper>
  );
}
