import React from 'react'
import styled from 'styled-components'
import {RoundedButton} from './Buttons'

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`
const Text = styled.div`
  align-self: center;
  color: #555;
  font-weight: bold;
  font-size: 4vw;
  @media screen and (min-width: 481px) {
    font-size: 1.8vw;
  }
`
const Type = styled(Text)`
  font-size: 3.5vw;
  font-family: lanove-pop;
`
const Name = styled(Text)`
`
const ThumbWrapper = styled.div`
  border-radius: 50%;
  background-color: #FF6969;
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin: 10px 0;
  box-shadow: 0px 0px 10px #FF6969;
`
const ThumbImage = styled.img`
  border-radius: 50%;
  width: 90%;
  height: 90%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const SelectButton = styled(RoundedButton)`
  @media screen and (min-width: 481px) {
    margin-bottom: 50px;
  }
`

const TypeSubText = styled.div`
`

function Thumb (props:any) {
  return (
    <div>
      <ThumbWrapper>
        <ThumbImage
          src={props.src}
        />
        </ThumbWrapper>
    </div>
  )
}

export default function SelectedActress(props:any) {
  const url = '../assets/images/actress.jpg'
  return (
    <Wrapper>
      <Type>
        {props.type}

      </Type>
      <Thumb
        src={props.url ? props.url : url}
        alt={props.name}
        />
      <Name>
        {props.name}
      </Name>
      {props.callback && (
        <SelectButton
          onClick={props.callback}
        >
          選択する
        </SelectButton>
      )}
    </Wrapper>
  );
}
