import React from 'react'
import styled, { keyframes } from 'styled-components'
import { RoundedButton } from './Buttons'

const AnimationBounce = keyframes`
  0% { opacity: 0; -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -100, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -100, 0, 1); }
  2.7% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -61.129, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -61.129, 0, 1); }
  5.41% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -8.423, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -8.423, 0, 1); }
  8.11% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 30.238, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 30.238, 0, 1); }
  10.71% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 42.564, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 42.564, 0, 1); }
  13.71% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 30.597, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 30.597, 0, 1); }
  16.62% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 7.685, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 7.685, 0, 1); }
  19.62% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -11.318, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -11.318, 0, 1); }
  22.52% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -17.407, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -17.407, 0, 1); }
  28.43% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -2.992, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -2.992, 0, 1); }
  34.33% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 7.117, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 7.117, 0, 1); }
  46.05% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -2.911, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -2.911, 0, 1); }
  57.86% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1.19, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1.19, 0, 1); }
  69.57% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.487, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.487, 0, 1); }
  81.38% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.199, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.199, 0, 1); }
  93.19% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.081, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.081, 0, 1); }
  100% {  opacity: 1;-webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
`

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 0 2vw;
  &:first-child {
    margin-left: 0px;
  }
  @media screen and (min-width: 481px) {
    max-width: 200px;
    margin: 0px 20px;
  }
`
const Text = styled.div`
  align-self: center;
  color: #555;
  font-weight: bold;
  font-size: 4.5vw;
  @media screen and (min-width: 481px) {
    font-size: 2vw;
  }
`
const Order = styled(Text)`
  font-size: 6vw;
  font-family: lanove-pop;
`
const Name = styled(Text)`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ThumbImage = styled.img`
  border-radius: 50%;
  width: 90%;
  height: 90%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const ListButton = styled(RoundedButton)`
  font-size: 3.2vw;
  padding: 2vw;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  & > img {
    padding-left: 1vw;
    width: 3vw;
    height: 3vw;
  }
  @media screen and (min-width: 481px) {
    font-size: 2vw;
    & > img {
      padding-left: 1vw;
      width: 1.5vw;
      height: 1.5vw;
    }
  }
`

const  ResultActress = (props:any) => {
  function Thumb(props: any) {
    const duration = "1000ms"
    let delay = props.order * 200 + "ms"
    const ThumbWrapper = styled.div`
      border-radius: 50%;
      background-color: #FF6969;
      position: relative;
      width: 100%;
      padding-top: 100%;
      margin: 10px 0;
      box-shadow: 0px 0px 10px #FF6969;
      animation: ${AnimationBounce} ${duration} ${delay} linear both;
      cursor: pointer;
    `
    return (
      <div
        onClick={() => {
          props.onClick()
        }}
      >
        <ThumbWrapper>
          <ThumbImage
            src={props.src}
            alt={props.alt}
          />
        </ThumbWrapper>
      </div>
    )
  }
  return (
    <Wrapper>
      <Order>
        No.{props.order}
      </Order>
      <Thumb
        src={props.actress.imageURL}
        alt={props.actress.name}
        order={props.order}
        onClick={() => {
          props.onClick()
        }}
        />
      <Name>
        {props.actress.name}
      </Name>
      <ListButton
        onClick={() => {
          props.onClick()
        }}
      >
        作品一覧
        <img src={require("../assets/images/new-window.png")}/>
      </ListButton>
    </Wrapper>
  );
}

export default React.memo(ResultActress)