import {DependencyList, useEffect, useState} from 'react';

export default function useSkip(effect: Function, deps: DependencyList, times: number = 1) {
  const [_times, setTimes] = useState(0);
  useEffect(() => {
    if (_times >= times) {
      return effect();
    } else {
      setTimes(_times + 1);
    }
  }, deps);
}