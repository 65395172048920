import { combineReducers, createStore, compose, applyMiddleware} from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {Reducer, State} from './reducer'
import thunk from "redux-thunk"

export type AppState = {
  state: State
}

const storeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// 永続化の設定
const persistConfig = {
  key: 'root', // Storageに保存されるキー名を指定する
  storage, // 保存先としてlocalStorageがここで設定される
  whitelist: ['actresses', 'searchInput', 'searchResults'] // Stateは`todos`のみStorageに保存する
  // blacklist: ['visibilityFilter'] // `visibilityFilter`は保存しない
}

const reducers = combineReducers<AppState>({
    state: Reducer
  })
// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(
    persistedReducer,
    storeEnhancers(applyMiddleware(thunk))
)

export const persistor = persistStore(store)
export default store