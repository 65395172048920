import React from 'react'
import styled from 'styled-components'

const height:number = 60

export const RuleWrapper = styled.div`
  text-align: center;
  margin: 20px 0px;
  padding: 0px 20px;
  & > a {
    border: solid 2px #FF6969;
    border-radius: 10px;
  }
`

export const DMMWrapper = styled.div`
  text-align: center;
  margin-bottom: 4vw;
  @media screen and (min-width: 481px) {
    margin-bottom: 1vw;
  }
`

export const FooterWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: ${height}px;
  width: 100%;
  background-color: #FF6969;
  color: #FFF;
  box-shadow: 0px 4px 4px #0003;
  justify-content: center;
  align-items: center;
  & * {
    box-sizing: border-box;
  }
`
const BaseText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  text-shadow: 2px 2px 4px #0004;
  font-size: 4vw;
  @media screen and (min-width: 481px) {
    font-size: 2vw;
  }
`

const Icon = styled.div`
  width: 20px;
  height: 20px;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 481px) {
    width: 2.2vw;
    height: 2.2vw;
  }
`

const CompanyName = styled.div`
  font-family: lanove-pop;
  margin: 0 5px;
`
export function CopyRight  (props:any) {
  return (
    <BaseText>
      CopyRight
      <CompanyName>
        {props.company_name}
      </CompanyName>
      2020 All Rights Reserved.
    </BaseText>
  )
}