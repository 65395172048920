import React from 'react'
import { useState } from "react"
import styled from 'styled-components'
import SearchActress from "./search-actress"
import {SearchButton} from "./Buttons"

export interface Suggestion {
  name: string,
  kana: string,
  image: string,
  id: string
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`

const FormWrapper  = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin 15px 0;
  width: 100%;
  & > * {
    width: 100%;
  }
  @media screen and (min-width: 481px) {
    flex-direction: row;
    margin: auto;
    max-width: 250px;
    min-width: 50%;
    padding: 30px;
    & > * {
      width: 30%;
    }
  }
`

const ButtonWrapper  = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export default function NukiSearch (props:any) {

  const [searchInputs, setSearchInputs] = useState(props.initialInputs)
  const isInput = () => {
    return searchInputs.face.id || searchInputs.style.id || searchInputs.type.id
  }

  return (
    <Wrapper>
      <FormWrapper>
        <SearchActress
          key={"face"}
          type={'顔は...'}
          value={searchInputs.face.name}
          image={searchInputs.face.image}
          actresses={props.actresses}
          onChange={(suggestion: Suggestion) => {
            setSearchInputs({
              ...searchInputs,
              face: {
                id: suggestion.id,
                name: suggestion.name,
                image: suggestion.image
              }
            })
          }}
          onTextChange={(value: string) => {
            setSearchInputs({
              ...searchInputs,
              face: {
                id: '',
                name: '',
                image: ''
              }
            })
          }}
        ></SearchActress>
        <SearchActress
          key={"style"}
          type={'スタイルは...'}
          value={searchInputs.style.name}
          image={searchInputs.style.image}
          actresses={props.actresses}
          onChange={(suggestion: Suggestion) => {
            setSearchInputs({
              ...searchInputs,
              style: {
                id: suggestion.id,
                name: suggestion.name,
                image: suggestion.image
              }
            })
          }}
          onTextChange={(value: string) => {
            setSearchInputs({
              ...searchInputs,
              style: {
                id: '',
                name: '',
                image: ''
              }
            })
          }}
        ></SearchActress>
        <SearchActress
          key={"type"}
          type={'ジャンルは...'}
          value={searchInputs.type.name}
          image={searchInputs.type.image}
          actresses={props.actresses}
          onChange={(suggestion: Suggestion) => {
            setSearchInputs({
              ...searchInputs,
              type: {
                id: suggestion.id,
                name: suggestion.name,
                image: suggestion.image
              }
            })
          }}
          onTextChange={(value: string) => {
            setSearchInputs({
              ...searchInputs,
              type: {
                id: '',
                name: '',
                image: ''
              }
            })
          }}
        ></SearchActress>
      </FormWrapper>
      <ButtonWrapper>
        <SearchButton
          onClick={ () => {
            props.handleSearch(props.firebase.firestore(), searchInputs)
          }}
          disabled={!isInput()}
        >サーチ結果にGO!!</SearchButton>
      </ButtonWrapper>
    </Wrapper>
  )
}
