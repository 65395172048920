import React from  'react';
import styled from  'styled-components';


const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`

const Popular = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px 0;
  width: 100%;
  & > * {
    width: 25%;
  }
  @media screen and (min-width: 481px) {
    margin: auto;
    max-width: 250px;
    min-width: 50%;
    padding: 30px;
  }
`

const SearchActressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin 15px 0 ;
  & > * {
    width: 30%;
  }
  @media screen and (min-width: 481px) {
    margin: auto;
    max-width: 250px;
    min-width: 50%;
    padding: 30px;
  }
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  @media screen and (min-width: 481px) {
    margin-bottom: 60px;
  }
`

const Message = styled.div`
  text-align: center;
  color: #FF6969;
  font-size: 4vw;
  font-family: lanove-pop;
  margin: 2vw 0;
  @media screen and (min-width: 481px) {
    font-size: 2vw;
  }
`

export {
  Wrapper,
  Popular,
  SearchWrapper,
  SearchActressWrapper,
  Message
}
