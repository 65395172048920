import { connect } from "react-redux";
import { Dispatch } from "redux";
import { selectActress, search, SearchInput, Actions } from "../action";
import Rule from "./index";
import { AppState } from "../store";

const mapStateToProps = (appState: AppState) => {
  return {
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    handleStopLoading: () => { dispatch(Actions.stopLoading({})) },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Rule)