import { connect } from "react-redux";
import { Dispatch } from "redux";
import { fetchRanking, fetchPopularSearchParam, search, Actions } from "../action";
import Home from "./index";
import { AppState } from "../store";
import names from '../assets/jsons/names.json'

const mapStateToProps = (appState: AppState) => {
  return {
    actresses: appState.state.actresses,
    didFetchActresses: appState.state.didFetchActresses,
    searchInput: appState.state.searchInput,
    isSearched: appState.state.isSearched,
    errorMessage: appState.state.errorMessage,
    popularSearch: appState.state.popularSearch,
    ranking: appState.state.ranking,
    firebase: appState.state.firebase,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    handleUpdateSearchInput: (param: any) => { dispatch(Actions.updateSearchInput(param)) },
    handleSearch: async (firebase:any, param: any) => {
      return search(firebase, param, dispatch)
    },
    handlePopularSearchParam: async (db:any) => {
      return fetchPopularSearchParam(db, dispatch)
    },
    handleFetchRanking: async (db:any) => {
      return fetchRanking(db, dispatch)
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)