import styled from  'styled-components';

const Container = styled.div`
  margin: auto;
`

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`

const ResultWrapper = styled.div`
  background-color: #FFF;
  @media screen and (min-width: 481px) {
    padding: 30px 0px;
  }
`

const ScrollWrapper = styled.div`
  padding: 0px 30px;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > * {
    min-width: 23vw;
  }
  @media screen and (min-width: 481px) {
    & > * {
      min-width: 15vw;
    }
  }
`

const Popular = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin 15px 0 ;
  & > * {
    width: 25%;
  }
  @media screen and (min-width: 481px) {
    margin: auto;
    max-width: 250px;
    min-width: 50%;
    padding: 30px;
  }
`

const SearchActressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin 15px 0 ;
  & > * {
    width: 30%;
  }
  @media screen and (min-width: 481px) {
    margin: auto;
    max-width: 250px;
    min-width: 50%;
    padding: 60px 30px;
  }
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media screen and (min-width: 481px) {
    margin-bottom: 60px;
  }
`

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`

const LoadingContent = styled.div`
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
`

const LoadingBack = styled.div`
  background-color: #000B;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`

export {
  Wrapper,
  ResultWrapper,
  ScrollWrapper,
  Popular,
  SearchWrapper,
  SearchActressWrapper,
  LoadingWrapper,
  LoadingContent,
  LoadingBack,
  Container
}
