import React, { useEffect, useMemo, useCallback } from 'react';
import {Container, ScrollWrapper, ResultWrapper, Wrapper} from  './style';
import Section from  '../ui-parts/section';
import ResultActress from  '../ui-parts/result-actress';
import { withRouter } from 'react-router-dom';
import {Ad} from '../ui-parts/Ad';
import NukiSearch from "../ui-parts/nuki-search"

function Result(props: any) {

  useEffect(() => {
    if (!props.searchInput.face.id && !props.searchInput.style.id && !props.searchInput.type.id) {
      props.history.push('/')
    }
    window.scrollTo(0, 0);
  }, [])

  useEffect( () => {
    if (props.isSearched) {
      window.scrollTo(0, 0);
    }
  }, [props.isSearched])

  const isSearched = useMemo(() => {
    return props.isSearched
  }, [props.isSearched])

  const results = useMemo(() => {
    return props.searchResults
  }, [props.searchResults])

  const Results = useCallback(() => {
    return (
      <ScrollWrapper>
        {isSearched && results.map((actress:any, i:number) => {
          return <ResultActress
            key={actress.id}
            order={i + 1}
            actress={actress}
            onClick={() => {
              props.handleSelectActress(props.firebase.firestore(), actress.id)
              window.open(actress.link, '_blank')
            }}
          />
        })}
      </ScrollWrapper>
    )
  }, [props.isSearched, props.searchResults])

  return (
    <Container>
      <Wrapper>
        <Section
          src={require('../assets/images/popular.png')}>
          サーチ結果
        </Section>
        {props.errorMessage.search != "" &&
          <div style={{fontWeight: "bold", color: "red"}}>{props.errorMessage.search}</div>
        }
      </Wrapper>
      <ResultWrapper>
        <Results
        ></Results>
      </ResultWrapper>
      <Wrapper>
        <Section
          src={require('../assets/images/search.png')}>
          ヌキサーチする
        </Section>
        <NukiSearch
          initialInputs={props.searchInput}
          actresses={props.actresses}
          firebase={props.firebase}
          handleSearch={props.handleSearch}
        />
        <Ad/>
        {/*<Section
          src={require('../assets/images/face.png')}>
          人気顔サーチ
        </Section>
        <Popular>
          {props.ranking.face.map((actress:any, order:number) => {
            return (
              <SelectedActress
                key={order}
                type={"No." + (order+1)}
                name={actress.name}
                url={actress.imageURL}
                callback={() => {
                  setFaceId(actress.id)
                  setFaceName(actress.name)
                  setFaceImage(actress.imageURL)
                }}
              />
            )
            })
          }
        </Popular>
        <Section
          src={require('../assets/images/style.png')}>
          人気スタイルサーチ
        </Section>
        <Popular>
        {props.ranking.style.map((actress:any, order: number) => {
          return (
            <SelectedActress
              key={order}
              type={"No." + (order+1)}
              name={actress.name}
              url={actress.imageURL}
              callback={() => {
                setStyleId(actress.id)
                setStyleName(actress.name)
                setStyleImage(actress.imageURL)
              }}
            />
          )
          })
        }
        </Popular>
        <Section
          src={require('../assets/images/genre.png')}>
          人気ジャンルサーチ
        </Section>
        <Popular>
        {props.ranking.type.map((actress:any, order:number) => {
          return (
            <SelectedActress
              key={order}
              type={"No." + (order+1)}
              name={actress.name}
              url={actress.imageURL}
              callback={() => {
                setTypeId(actress.id)
                setTypeName(actress.name)
                setTypeImage(actress.imageURL)
              }}
            />
          )
          })
        }
      </Popular>*/}
      </Wrapper>
    </Container>
    );
  }
  export default withRouter(Result);