import React from 'react'
import {Wrapper, Title, Desc} from './style'
import { withRouter } from 'react-router';

function Header(props:any) {
  return (
    <Wrapper>
      <Title
        onClick={() => {
          props.history.push('/')
        }}
      >ヌキサーチ</Title>
      <Desc>ヌケるAV女優を<div>顔・スタイル・ジャンル</div><div>から検索っ!!</div></Desc>
    </Wrapper>
  );
}

export default withRouter(Header);
