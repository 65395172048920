import React from 'react'
import {DMMWrapper, FooterWrapper, RuleWrapper, CopyRight} from './style'
import {RuleButton, NewWindowButton} from '../ui-parts/Buttons'
import { withRouter } from 'react-router-dom';

const Footer = (props: any) => {
  return (
    <div>
      <RuleWrapper>
        <RuleButton onClick={() => {
          props.history.push('/rule')
        }}>
          利用規約
        </RuleButton>
      </RuleWrapper>
      <RuleWrapper>
        <NewWindowButton onClick={() => {
          window.open('https://docs.google.com/forms/d/e/1FAIpQLSfxMn6-07bKFVBPSpX5YGXRPqD-_kRBLRmX6u6uP16kmomtZw/viewform', '_blank')
        }}>
          お問い合わせ・ご要望
          <img
            src={require("../assets/images/new-window.png")}
            />
        </NewWindowButton>
      </RuleWrapper>
      <RuleWrapper>
        <NewWindowButton onClick={() => {
          window.open("https://note.com/nuki_search", '_blank')
        }}>
          管理人のヌキブログ
          <img
            src={require("../assets/images/new-window.png")}
            />
        </NewWindowButton>
      </RuleWrapper>
      <DMMWrapper>
        <a href="https://affiliate.dmm.com/api/"><img src="https://p.dmm.co.jp/p/affiliate/web_service/r18_135_17.gif" width="135" height="17" alt="WEB SERVICE BY FANZA" /></a>
      </DMMWrapper>
      <FooterWrapper>
        <CopyRight
          company_name={'ヌキサーチ'}
        ></CopyRight>
      </FooterWrapper>
    </div>
  );
}

export default withRouter(Footer);
