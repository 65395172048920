import { actionCreatorFactory } from "../node_modules/typescript-fsa";
import { Dispatch } from "redux";
import firebase from "firebase";
import "firebase/auth";

export const prepareFirebase = (dispatch: Dispatch) => {
  dispatch(Actions.prepareFirebase.started({ message: "fetch start." }));
  try {
    if (process.env.NODE_ENV === "production") {
      fetch("/__/firebase/init.json")
        .then(async response => {
          firebase.initializeApp(await response.json());
          dispatch(
            Actions.prepareFirebase.done({
              params: { message: "" },
              result: { firebase: firebase },
            })
          );
        })
        .catch(e => {
          throw e;
        });
    } else {
      const config = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BACKET,
        appId: process.env.REACT_APP_APP_ID,
      };
      firebase.initializeApp(config);
      dispatch(
        Actions.prepareFirebase.done({
          params: { message: "" },
          result: { firebase: firebase },
        })
      );
    }
  } catch (error) {
    dispatch(
      Actions.prepareFirebase.failed({
        params: { message: "データを取得できませんでした" },
        error: error,
      })
    );
  }
};

export const selectActress = (db: any, id: string) => {
  console.log("SELECT ACTRESS");
  const ref = db.collection("selectCountActress").doc(id);
  ref
    .get()
    .then((doc: any) => {
      const selectCountData = doc.data();
      let selectCount = 0;
      if (selectCountData) {
        selectCount = selectCountData["count"];
      }
      return ref.set({
        count: selectCount + 1,
      });
    })
    .catch((e: any) => {
      console.log(e);
    });
};

const actionCreator = actionCreatorFactory();

export const fetchSearchResultActions = actionCreator.async<
  { message: string }, // parameter type
  { results: any }, // success type
  { code: number } // error type
>("FETCH_SEARCH_RESULT");

export interface ResponseActress {
  id: string;
  image_large: string;
  name: string;
}

export interface SearchInput {
  face: {
    id: string;
    name: string;
    image: string;
  };
  style: {
    id: string;
    name: string;
    image: string;
  };
  type: {
    id: string;
    name: string;
    image: string;
  };
}
export interface SearchParam {
  face_id: string;
  style_id: string;
  type_id: string;
}
export interface SearchResult {
  name: string;
  id: string;
  link: string;
  imageURL: string;
  face_diff: number;
  style_diff: number;
  type_diff: number;
}
export interface PopularSearchResult {
  face: {
    id: string;
    name: string;
    imageURL: string;
  };
  type: {
    id: string;
    name: string;
    imageURL: string;
  };
  style: {
    id: string;
    name: string;
    imageURL: string;
  };
}

export interface RankingResult {
  face: Array<{
    id: string;
    name: string;
    imageURL: string;
  }>;
  type: Array<{
    id: string;
    name: string;
    imageURL: string;
  }>;
  style: Array<{
    id: string;
    name: string;
    imageURL: string;
  }>;
}

export interface ActressData {
  name: string;
  kana: string;
  id: string;
}

export const Actions = {
  updateSearchInput: actionCreator<SearchInput>("ACTIONS_UPDATE_SEARCH_INPUT"),
  fetchSearchResult: actionCreator.async<{ message: string }, any, string>(
    "ACTIONS_FETCH_SEARCH_RESULT"
  ),
  fetchPopularSearchResult: actionCreator.async<
    { message: string },
    PopularSearchResult,
    string
  >("ACTIONS_POPULAR_FETCH_SEARCH_RESULT"),
  fetchRankingResult: actionCreator.async<
    { message: string },
    RankingResult,
    string
  >("ACTIONS__RANKING_RESULT"),
  prepareFirebase: actionCreator.async<{ message: string }, any, string>(
    "ACTIONS_PREPARE_FIREBASE"
  ),
  selectActress: actionCreator<any>("ACTIONS_SELECT_ACTRESS"),
  stopLoading: actionCreator<any>("ACTIONS_STOP_LOADING"),
};

export function search(db: any, param: SearchInput, dispatch: Dispatch) {
  // リクエスト開始のActionをdispatch
  dispatch(Actions.fetchSearchResult.started({ message: "fetch start." }));
  const requestParam: any = {
    face: param.face.id,
    face_coef: 30,
    style: param.style.id,
    style_coef: 5,
    type: param.type.id,
    type_coef: 10,
  };
  console.log("requestParam");
  console.log(requestParam);
  const URL =
    "https://asia-northeast1-nuki-search.cloudfunctions.net/nuki-search-calc?" +
    new URLSearchParams(requestParam).toString();
  return fetch(URL, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((result: any) => result.json())
    .then(async (data: any) => {
      if (typeof data.data === "string") {
        throw data.data;
      }
      let actresses: SearchResult[] = [];
      for (let ttt of data.data) {
        console.log("ttt");
        console.log(ttt);
        actresses.push({
          name: ttt.name,
          id: ttt.id,
          link:
            "https://www.dmm.co.jp/search/=/searchstr=" +
            ttt.name +
            "/sort=rankprofile/",
          imageURL: ttt.image_large
            ? ttt.image_large.replace("http:", "https:")
            : require("./assets/images/noimage.png"),
          face_diff: ttt.face_diff,
          style_diff: ttt.style_diff,
          type_diff: ttt.type_diff,
        });
      }
      dispatch(
        Actions.fetchSearchResult.done({
          params: { message: "" },
          result: { actresses: actresses, param: param },
        })
      );

      // 顔検索数カウント
      if (param.face.id !== "") {
        const faceRef = db.collection("faceSearchCount").doc(param.face.id);
        const faceDoc = await faceRef.get();
        const faceCountData = faceDoc.data();
        let faceCount = 0;
        if (faceCountData) {
          faceCount = faceCountData["count"];
        }
        await faceRef.set({
          count: faceCount + 1,
        });
      }
      // スタイル検索数カウント
      if (param.style.id !== "") {
        const styleRef = db.collection("styleSearchCount").doc(param.style.id);
        const styleDoc = await styleRef.get();
        const styleCountData = styleDoc.data();
        let styleCount = 0;
        if (styleCountData) {
          styleCount = styleCountData["count"];
        }
        await styleRef.set({
          count: styleCount + 1,
        });
      }
      // ジャンル検索数カウント
      if (param.type.id !== "") {
        const typeRef = db.collection("typeSearchCount").doc(param.type.id);
        const typeDoc = await typeRef.get();
        const typeCountData = typeDoc.data();
        let typeCount = 0;
        if (typeCountData) {
          typeCount = typeCountData["count"];
        }
        await typeRef.set({
          count: typeCount + 1,
        });
      }
    })
    .catch((error: any) => {
      console.log("error");
      console.log(error.message);
      console.log(error.details);
      dispatch(
        Actions.fetchSearchResult.failed({
          params: { message: "AV女優を選択してください" },
          error: error,
        })
      );
    });
}

export const fetchPopularSearchParam = (db: any, dispatch: Dispatch) => {
  dispatch(
    Actions.fetchPopularSearchResult.started({ message: "fetch start." })
  );
  db.collection("popular_search")
    .doc("main")
    .get()
    .then((res: any) => {
      const data: any = res.data();
      const result: PopularSearchResult = {
        face: {
          id: data["face"]["id"],
          name: data["face"]["name"],
          imageURL: data["face"]["image_url"].replace("http:", "https:"),
        },
        type: {
          id: data["type"]["id"],
          name: data["type"]["name"],
          imageURL: data["type"]["image_url"].replace("http:", "https:"),
        },
        style: {
          id: data["style"]["id"],
          name: data["style"]["name"],
          imageURL: data["style"]["image_url"].replace("http:", "https:"),
        },
      };
      dispatch(
        Actions.fetchPopularSearchResult.done({
          params: { message: "" },
          result: result,
        })
      );
    })
    .catch((error: any) => {
      dispatch(
        Actions.fetchPopularSearchResult.failed({
          params: { message: "データを取得できませんでした" },
          error: error,
        })
      );
    });
};

export const fetchRanking = (db: any, dispatch: Dispatch) => {
  dispatch(Actions.fetchRankingResult.started({ message: "fetch start." }));
  db.collection("ranking")
    .doc("main")
    .get()
    .then((res: any) => {
      const data: any = res.data();
      const result: any = { face: [], type: [], style: [] };
      for (const face of data.face) {
        result["face"].push({
          id: face["id"],
          name: face["name"],
          imageURL: face["image_url"].replace("http:", "https:"),
        });
      }
      for (const style of data.style) {
        result["style"].push({
          id: style["id"],
          name: style["name"],
          imageURL: style["image_url"].replace("http:", "https:"),
        });
      }
      for (const type of data.type) {
        result["type"].push({
          id: type["id"],
          name: type["name"],
          imageURL: type["image_url"].replace("http:", "https:"),
        });
      }
      console.log("ranking result");
      console.log(result);
      dispatch(
        Actions.fetchRankingResult.done({
          params: { message: "" },
          result: result,
        })
      );
    })
    .catch((error: any) => {
      dispatch(
        Actions.fetchPopularSearchResult.failed({
          params: { message: "データを取得できませんでした" },
          error: error,
        })
      );
    });
};
