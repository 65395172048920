import React, { useEffect } from 'react';
import styled from  'styled-components';
import {NukiButton} from  '../ui-parts/Buttons';
import { withRouter } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 4vw;
`
const Title = styled.p`
  font-weight: bold;
`

const ButtonWrapper = styled.div`
  padding: 0 10px;
  color: #FF6969;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Rule = (props: any) => {
  useEffect(() => {
    props.handleStopLoading()
    window.scrollTo(0, 0);
  }, [])

  return (
    <Wrapper>
      <h2>利用規約</h2>
      この利用規約（以下，「本規約」といいます。）は ヌキサーチ（以下，「当サービス」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。ご利用ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
      <ul>
        <li>
          <Title>第1条（適用）</Title>
          <p>
            <div>本規約は，ユーザーと当サービスとの間の本サービスの利用に関わる一切の関係に適用されるものとします。</div>
            <div>当サービスは本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。</div>
            <div>本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</div>
          </p>
        </li>
        <li>
          <Title>第2条（禁止事項）</Title>
          <p>
            <div>ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</div>
            <div>法令または公序良俗に違反する行為</div>
            <div>犯罪行為に関連する行為</div>
            <div>本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</div>
            <div>当サービス，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</div>
            <div>当サービスのサービスの運営を妨害するおそれのある行為</div>
            <div>他のユーザーに関する個人情報等を収集または蓄積する行為</div>
            <div>本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</div>
            <div>当サービスのサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</div>
            <div>その他，当サービスが不適切と判断する行為</div>
          </p>
        </li>
        <li>
          <Title>第3条（本サービスの提供の停止等）</Title>
          <p>
            <div>当サービスは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</div>
            <div>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</div>
            <div>地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</div>
            <div>コンピュータまたは通信回線等が事故により停止した場合</div>
            <div>その他，当サービスが本サービスの提供が困難と判断した場合</div>
            <div>当サービスは，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</div>
          </p>
        </li>
        <li>
          <Title>第4条（保証の否認および免責事項）</Title>
          <p>
            <div>当サービスは，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</div>
            <div>当サービスは，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。</div>
            <div>当サービスは，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。</div>
          </p>
        </li>
        <li>
          <Title>第5条（サービス内容の変更等）</Title>
          <p>
            当サービスは，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
          </p>
        </li>
        <li>
          <Title>第6条（利用規約の変更）</Title>
          <p>
            当サービスは，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
          </p>
        </li>
        <li>
          <Title>第7条（個人情報の取扱い）</Title>
          <p>
            <div>当サービスは，本サービスの利用によって取得する全ての情報</div>
            <div>・検索内容</div>
            <div>・アンケートへの回答</div>
            <div>・選択したコンテンツの内容の情報</div>
            <div>・アクセス元の情報</div>
            <div>・アクセス端末情報 (機種・ブラウザ・OS等)</div>
            <div>については，当サービス内容の充実、独自のデータ分析にのみ利用します。</div>
          </p>
        </li>
        <li>
          <Title>第8条（権利義務の譲渡の禁止）</Title>
          <p>
            ユーザーは，当サービスの書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
          </p>
        </li>
        <li>
          <Title>第9条（準拠法・裁判管轄）</Title>
          <p>
            <div>本規約の解釈にあたっては，日本法を準拠法とします。</div>
            <div>本サービスに関して紛争が生じた場合には，当サービスの本店所在地を管轄する裁判所を専属的合意管轄とします。</div>
          </p>
        </li>
      </ul>
      <ButtonWrapper>
        <NukiButton
          onClick={() => {
            props.history.push('/')
          }}
        >＜戻る</NukiButton>
      </ButtonWrapper>
    </Wrapper>
  )
}
export default withRouter(Rule);