import React, {useState} from 'react';
import Lottie from 'react-lottie';
import LoadingLottie from '../assets/jsons/searching.json';
import useSkip from "../useSkip";
import styled from  'styled-components';

const Text = styled.div`
  color: #FFF;
  font-weight: thin;
  font-size: 5vw;
  font-family: lanove-pop;
  @media screen and (min-width: 481px) {
    font-size: 2vw;
  }
`

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default function Loading (props: any) {
  const [isDisplay, setIsDisplay] = useState<any>(false)
  const [threadId, setThreadId] = useState<any>(false)

  const LoadingWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    background: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transaction-duration: 0.4s;
    pointer-events: none;
    opacity: ${isDisplay ? 1 : 0};
    transition-property: 'opacity';
    transition-timing-function: 'ease-out';
  `

  useSkip(() => {
    if (props.isShow) {
      if (isDisplay) return
      setIsDisplay(true)
    } else {
      if (threadId) {
        clearTimeout(threadId)
      }
      const id = setTimeout(() => {
        setIsDisplay(false)
      }, 300)
      setThreadId(id)
    }
  }, [props.isShow])

  return (
  <LoadingWrapper
    onTransitionEnd={(e) => {
      setThreadId(false)
    }}
    >
    <Lottie
      options={lottieOptions}
      height={200}
      width={200}
      isStopped={false}
      isPaused={false}
    />
    <Text>
      {props.text}
    </Text>
  </LoadingWrapper>
  )
}

