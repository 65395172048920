import React from 'react'
import Iframe from 'react-iframe'

export function Ad () {
  return (
    <Iframe
      url="./ad.html"
      frameBorder={0}
    />
  )
}