import { connect } from "react-redux";
import { Dispatch } from "redux";
import { selectActress, search, SearchInput, Actions } from "../action";
import Home from "./index";
import { AppState } from "../store";

const mapStateToProps = (appState: AppState) => {
  return {
    actresses: appState.state.actresses,
    didFetchActresses: appState.state.didFetchActresses,
    searchInput: appState.state.searchInput,
    searchResults: appState.state.searchResults,
    isSearched: appState.state.isSearched,
    errorMessage: appState.state.errorMessage,
    isLoading: appState.state.isLoading,
    ranking: appState.state.ranking,
    firebase: appState.state.firebase
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    handleUpdateSearchInput: (param: SearchInput) => { dispatch(Actions.updateSearchInput(param)) },
    handleSearch: async (firebase:any, param: SearchInput) => {
      return search(firebase, param, dispatch)
    },
    handleSelectActress: (db: any, id: string) => { selectActress(db, id)},
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)