import React from 'react'
import styled from 'styled-components'

const height:number = 60
const pcHeight:number = 90

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: ${height}px;
  width: 100%;
  background-color: #FF6969;
  font-family: lanove-pop;
  color: #FFF;
  box-shadow: 0px 4px 4px #0003;
  & * {
    box-sizing: border-box;
  }
  @media screen and (min-width: 481px) {
    height: ${pcHeight}px;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 50%;
  padding-left: 10px;
  @media screen and (min-width: 481px) {
    padding-left: 60px;
  }
`
const Icon = styled.div`
  padding-top: 5px;
  height: 50%;
  filter: drop-shadow(2px 2px 4px #666);
  width: 6.5vw;
  & > img {
    height: 100%;
    width: 100%;
  }
`
const TitleText = styled.h1`
  display: flex;
  align-items: center;
  font-size: 7vw;
  padding-left: 2px;
  text-shadow: 2px 2px 4px #0004;
  color: #FFF;
  text-decoration: none;
  @media screen and (min-width: 481px) {
    font-size: 2.5vw;
  }
`
function Title (props:any) {
  return (
    <TitleWrapper
      onClick={props.onClick}
    >
      <Icon>
        <img
          alt='eronator'
          src={require("../assets/images/icon.svg")}
        />
      </Icon>
      <TitleText>{props.children}</TitleText>
    </TitleWrapper>
  )
}

const Desc = styled.h2`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
  text-align: center;
  @media screen and (min-width: 481px) {
    flex-direction: row;
    font-size: 1.6vw;
  }
`

export {
  Wrapper,
  Title,
  Desc
};