import React, { useEffect } from 'react';
import Home from './home/container';
import Result from './result/container';
import Header from './header/index';
import Footer from './footer/index';
import Rule from './rule/container';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GlobalFonts from './assets/fonts/index';
import Loading from './ui-parts/Loading'
import { Dispatch } from 'redux';
import { AppState } from './store';
import { connect } from 'react-redux';
import { prepareFirebase } from './action'
import styled from  'styled-components';
import backgroundImage from './assets/images/background-tile.png'

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-attachment: fixed;
`

function App(props: {firebase: any, handlePrepareFirebase: any, isLoading: boolean}) {
  useEffect(() => {
   if (!props.firebase) props.handlePrepareFirebase()
  }, [])
  return (
    <Container>
      <BrowserRouter>
        <GlobalFonts />
        <Header/>
        {props.firebase ? (
        <Switch>
          <Route exact path='/result'><Result /></Route>
          <Route exact path='/rule'><Rule /></Route>
          <Route exact><Home /></Route>
        </Switch>
        ) : (
          <div></div>
        )
        }
        <Footer/>
        <Loading isShow={props.isLoading} text={"読み込み中..."}/>
      </BrowserRouter>
    </Container>
  );
}

const mapStateToProps = (appState: AppState) => {
  return {
    isLoading: appState.state.isLoading,
    firebase: appState.state.firebase
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    handlePrepareFirebase: async (db:any) => {
      return prepareFirebase(dispatch)
    }

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
