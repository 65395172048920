import styled from 'styled-components'

const Text = styled.div`
  align-self: center;
  color: #555;
  font-weight: bold;
`
const Type = styled(Text)`
  font-size: 1.2rem;
  font-family: lanove-pop;
`

export {
  Text,
  Type
}