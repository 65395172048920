import React, { useEffect, useState } from 'react';
import {Wrapper, Popular, SearchWrapper, Message} from  './style';
import {Ad} from '../ui-parts/Ad';
import Section from  '../ui-parts/section';
import SelectedActress from  '../ui-parts/selected-actress';
import {SearchButton} from '../ui-parts/Buttons'
import { withRouter } from 'react-router-dom';
import NukiSearch from "../ui-parts/nuki-search"

function Home(props:any) {

  const [isFirst, setIsFirst] = useState(true)
  let isShort = false
  if (matchMedia("screen and (max-width: 320px)").matches) {
    isShort = true
  }

  useEffect( () => {
    props.handlePopularSearchParam(props.firebase.firestore())
    props.handleFetchRanking(props.firebase.firestore())
    setIsFirst(false)
    window.scrollTo(0, 0);
  }, [])

  useEffect( () => {
    if (!isFirst && props.isSearched) {
      props.history.push('/result')
    }
  }, [props.isSearched])

  const getPopularParam = () => {
    const param = props.popularSearch
    return {
      face: {
        id: param.face.id,
        name: param.face.name,
        image: param.face.imageURL
      },
      type: {
        id: param.type.id,
        name: param.type.name,
        image: param.type.imageURL
      },
      style: {
        id: param.style.id,
        name: param.style.name,
        image: param.style.imageURL
      }
    }
  }

  return (
    <Wrapper>
      <Message>
        近日、芸能人対応予定!!
      </Message>
      <Ad></Ad>
      <Section
        src={require('../assets/images/popular.png')}>
        人気ヌキサーチ
      </Section>
      <Popular>
        <SelectedActress
          type={isShort ? '顔は' : '顔は...'}
          name={props.popularSearch.face.name}
          url={props.popularSearch.face.imageURL}
        />
        <SelectedActress
          type={isShort ? 'スタイルは' : 'スタイルは...'}
          name={props.popularSearch.style.name}
          url={props.popularSearch.style.imageURL}
        />
        <SelectedActress
          type={isShort ? 'ジャンルは' : 'ジャンルは...'}
          name={props.popularSearch.type.name}
          url={props.popularSearch.type.imageURL}
        />
      </Popular>
      <SearchWrapper>
        <SearchButton
          onClick={ () => {
            props.handleSearch(props.firebase.firestore(), getPopularParam())
          }}
        >サーチ結果にGO!!</SearchButton>
      </SearchWrapper>
      <Section
        src={require('../assets/images/search.png')}>
        ヌキサーチする
      </Section>
      <NukiSearch
        initialInputs={props.searchInput}
        actresses={props.actresses}
        firebase={props.firebase}
        handleSearch={props.handleSearch}
      />
      <Ad></Ad>
      {/*<Section
        src={require('../assets/images/face.png')}>
        人気顔サーチ
      </Section>
      <Popular>
        {props.ranking.face.map((actress:any, order:any) => {
          return (
            <SelectedActress
              key={order}
              type={"No." + (order+1)}
              name={actress.name}
              url={actress.imageURL}
              callback={() => {
                setFaceId(actress.id)
                setFaceName(actress.name)
                setFaceImage(actress.imageURL)
              }}
            />
          )
          })
        }
      </Popular>
      <Section
        src={require('../assets/images/style.png')}>
        人気スタイルサーチ
      </Section>
      <Popular>
        {props.ranking.style.map((actress:any, order:any) => {
          return (
            <SelectedActress
              key={order}
              type={"No." + (order+1)}
              name={actress.name}
              url={actress.imageURL}
              callback={() => {
                setStyleId(actress.id)
                setStyleName(actress.name)
                setStyleImage(actress.imageURL)
              }}
            />
          )
          })
        }
      </Popular>
      <Section
        src={require('../assets/images/genre.png')}>
        人気ジャンルサーチ
      </Section>
      <Popular>
        {props.ranking.type.map((actress:any, order:any) => {
          return (
            <SelectedActress
              key={order}
              type={"No." + (order+1)}
              name={actress.name}
              url={actress.imageURL}
              callback={() => {
                setTypeId(actress.id)
                setTypeName(actress.name)
                setTypeImage(actress.imageURL)
              }}
            />
          )
          })
        }
      </Popular>*/}
    </Wrapper>
  );
}

export default withRouter(Home);
