import { reducerWithInitialState } from '../node_modules/typescript-fsa-reducers';
import { RankingResult, PopularSearchResult, SearchResult, SearchInput, Actions } from './action';
import names from './assets/jsons/names.json'

export interface State {
    inputValue: string
    selectedValue: string
    clickCount: number
    actresses: {name: string, kana: string}[]
    didFetchActresses: boolean,
    searchInput: SearchInput,
    searchResults: SearchResult[],
    isSearched: boolean,
    errorMessage: {
      search: string
    },
    popularSearch: PopularSearchResult,
    ranking: RankingResult,
    isLoading: boolean,
    firebase: any
}

export const initialState: State = {
    inputValue: '',
    selectedValue: '',
    clickCount: 0,
    actresses: names,
    didFetchActresses: false,
    searchInput: {
      face: {
        name: '',
        id: '',
        image: ''
      },
      style: {
        name: '',
        id: '',
        image: ''
      },
      type: {
        name: '',
        id: '',
        image: ''
      }
    },
    searchResults: [],
    isSearched: false,
    errorMessage: {
      search: ''
    },
    popularSearch: {
      face: {
        id: '',
        name: '',
        imageURL: '',
      },
      type: {
        id: '',
        name: '',
        imageURL: '',
      },
      style: {
        id: '',
        name: '',
        imageURL: '',
      }
    },
    ranking: {
      face: [],
      type: [],
      style: []
    },
    isLoading: false,
    firebase: false
}

export const Reducer = reducerWithInitialState(initialState)
  .case(Actions.updateSearchInput, (state, searchInput) => {
    return { ...state, searchInput: searchInput}
  })
  .case(Actions.fetchSearchResult.started, (state, payload) => {
    return { ...state, searchResults: [], isLoading: true, isSearched: false, errorMessage: {search: ""}}
  })
  .case(Actions.fetchSearchResult.done, (state, payload) => {
    return { ...state, searchInput: payload.result.param, searchResults: payload.result.actresses, isSearched: true, isLoading: false, errorMessage: {search: payload.params.message}}
  })
  .case(Actions.fetchSearchResult.failed, (state, payload) => {
    return { ...state, errorMessage: {search: payload.params.message}, isSearched: true}
  })
  .case(Actions.fetchPopularSearchResult.done, (state, payload) => {
    console.log(payload.result)
    return { ...state, popularSearch: payload.result, isLoading: false}
  })
  .case(Actions.fetchRankingResult.done, (state, payload) => {
    console.log(payload.result)
    return { ...state, ranking: payload.result}
  })
  .case(Actions.prepareFirebase.started, (state, payload) => {
    return { ...state, isLoading: true}
  })
  .case(Actions.prepareFirebase.done, (state, payload) => {
    return { ...state, firebase: payload.result.firebase}
  })
  .case(Actions.stopLoading, (state, payload) => {
    return { ...state, isLoading: false}
  })